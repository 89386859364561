const files = [
  {
    title: "Mubashir's T56 Swapped Impala",
    size: "May 14th 2022",
    source: "https://i.imgur.com/LChNP8A.jpg",
  },
  {
    title: "Greg's SS with 15,000km on it",
    size: "May 14th 2022",
    source: "https://i.imgur.com/wiPWrFd.jpg",
  },
  {
    title: "Tylers DCM with black interior",
    size: "May 14th 2022",
    source: "https://i.imgur.com/RvwwwZc.jpg",
  },
  {
    title: "Jaime's Impala SS",
    size: "May 14th 2022",
    source: "https://i.imgur.com/E03m7c3.jpg",
  },
  {
    title: "Matthews Caprice with a 396 CI golen engine",
    size: "May 14th 2022",
    source: "https://i.imgur.com/aXdpGlV.jpg",
  },
  {
    title: "Engine bay in question",
    size: "May 14th 2022",
    source: "https://i.imgur.com/XCsW8cK.jpg",
  },
  {
    title: "Justin's DGGM on 22s",
    size: "May 14th 2022",
    source: "https://i.imgur.com/lrAWRUp.jpg",
  },
  {
    title: "Pete's SS with 500km + on it",
    size: "May 14th 2022",
    source: "https://i.imgur.com/8Lq1ov4.jpg",
  },
  {
    title: "Jaime's engine bay with some old school goodies",
    size: "May 14th 2022",
    source: "https://i.imgur.com/lw8Hemt.jpg",
  },
  {
    title: "TAISSO Season Opener",
    size: "May 14th 2022",
    source: "https://i.imgur.com/DA8Pv31.jpg",
  },
  {
    title: "TAISSO Season Opener",
    size: "May 14th 2022",
    source: "https://i.imgur.com/zaCsxMB.jpg",
  },
  {
    title: "TAISSO Season Opener",
    size: "May 14th 2022",
    source: "https://i.imgur.com/7fQz5mZ.jpg",
  },
  {
    title: "TAISSO Season Opener",
    size: "May 14th 2022",
    source: "https://i.imgur.com/637choq.jpg",
  },
  {
    title: "TAISSO Season Opener",
    size: "May 14th 2022",
    source: "https://i.imgur.com/2Ot0KfV.jpg",
  },
  {
    title: "TAISSO Season Opener",
    size: "May 14th 2022",
    source: "https://i.imgur.com/94m5jAJ.jpg",
  },

  {
    title: "TAISSO Season Opener",
    size: "May 14th 2022",
    source: "https://i.imgur.com/bYVQWOU.jpg",
  },
  // More files...
];

export function getFiles() {
  return files;
}
